import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'checkin-page',
  templateUrl: './checkin-page.component.html',
  styleUrls: ['./checkin-page.component.css']
})
export class CheckinPageComponent implements OnInit {
  eventID: string;
  eventName: string;
  eventDay: string;
  checkinForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.route.params.subscribe(routeParams => {
      this.eventID = routeParams['event'];
      this.eventDay = routeParams['day'];
      if (this.eventID == 'NextGen') {
        this.eventName = "Next Generation - Irvine";
      }
      if (this.eventID == 'leadership-summit') {
        this.eventName = 'Leadership Summit Training Conference';
      }
    });

  }

  ngOnInit() {
    this.checkinForm = this.fb.group({
      first:  [,Validators.required],
      last:  [,Validators.required],
      email: [,[Validators.email,Validators.required]],
      phone:  [,Validators.required],
      membership: '',
      id: null,
      sponsor: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    })
  }

  submitHandler() {
    let data: any = {
      first:  this.checkinForm.get('first').value,
      last:  this.checkinForm.get('last').value,
      email: this.checkinForm.get('email').value,
      phone:  this.checkinForm.get('phone').value,
      membership: this.checkinForm.get('membership').value,
      // id: null,
      // sponsor: null,
      // address: null,
      // city: null,
      // state: null,
      // zip: null,
      time: Date.now(),
    }
    if (data.membership == 'associate') {
      data.id = this.checkinForm.get('id').value
    } else if (data.membership == 'guest') {
      data.sponsor = this.checkinForm.get('sponsor').value
      data.address = this.checkinForm.get('address').value
      data.city = this.checkinForm.get('city').value
      data.state = this.checkinForm.get('state').value
      data.zip = this.checkinForm.get('zip').value
    }
    try {
      this.db.collection('events').doc(this.eventID).collection('days').doc(this.eventDay).collection('attendees').add(data)
        .then(() => {
          this.router.navigateByUrl(`${this.eventID}/${this.eventDay}/checkedin`);
        });

    } catch(err) {
      console.error(err)
    }
  }
}
