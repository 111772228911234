import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule , JsonpModule} from '@angular/http';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { InputSwitchModule } from 'primeng/inputswitch';

// AngularFire2 Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

// App components
import { CheckinPageComponent } from './checkin-page/checkin-page.component';
import { AppRoutingModule } from './app-routing.module';
import { InPageComponent } from './in-page/in-page.component';
import { SpashComponent } from './spash/spash.component';
import { AdminComponent } from './admin/admin.component';
import { DallasComponent } from './dallas/dallas.component';
import { DallasInComponent } from './dallas-in/dallas-in.component';

@NgModule({
  declarations: [
    AppComponent,
    CheckinPageComponent,
    InPageComponent,
    SpashComponent,
    AdminComponent,
    DallasComponent,
    DallasInComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase
    AngularFirestoreModule,
    HttpModule,
    JsonpModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    InputSwitchModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
