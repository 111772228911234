import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';



@Component({
  selector: 'app-in-page',
  templateUrl: './in-page.component.html',
  styleUrls: ['./in-page.component.css']
})
export class InPageComponent implements OnInit {
  eventName: string;
  eventDay: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(routeParams => {
      this.eventName = routeParams['event'];
      this.eventDay = routeParams['day'];
    });
  }

  ngOnInit() {
  }

  nextCheckin() {
    this.router.navigateByUrl(`${this.eventName}/${this.eventDay}`)
  }

}
