import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dallas-in',
  templateUrl: './dallas-in.component.html',
  styleUrls: ['./dallas-in.component.css']
})
export class DallasInComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
