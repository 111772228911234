import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'spash',
  templateUrl: './spash.component.html',
  styleUrls: ['./spash.component.css']
})
export class SpashComponent implements OnInit {

  constructor(
    private router: Router,
  ) {
    this.router.navigateByUrl(`leadership-summit/1`)
  }

  ngOnInit() {
  }

}
