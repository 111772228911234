import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckinPageComponent } from './checkin-page/checkin-page.component';
import { InPageComponent } from './in-page/in-page.component';
import { SpashComponent } from './spash/spash.component'
import { AdminComponent } from './admin/admin.component'
import { DallasComponent } from './dallas/dallas.component';
import { DallasInComponent } from './dallas-in/dallas-in.component';

const routes: Routes = [
  { path: '', component: SpashComponent },
  { path: 'dallas', component: DallasComponent },
  { path: 'dallas-in', component: DallasInComponent },
  { path: ':event/:day', component: CheckinPageComponent },
  { path: ':event/:day/checkedin', component: InPageComponent },
  { path: 'admin', component: AdminComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
