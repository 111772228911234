import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Parser} from 'json2csv'
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  day2a: any[];
  day2g: any[];
  day1a: any[];
  day1g: any[];
  day2Ref: AngularFirestoreCollection<any>;
  day1Ref: AngularFirestoreCollection<any>;
  irvinea: any[];
  irvineg: any[];
  irvineRef: AngularFirestoreCollection<any>;

  peepsCol: AngularFirestoreCollection;
  peeps: Observable<any[]>

  constructor(
    private db: AngularFirestore,
  ) {
    // this.peepsCol = this.db.collection('events').doc('nfl_alumni').collection('days').doc('1').collection('attendees')
    // this.peeps = this.peepsCol.valueChanges();
    // var clone = this.peeps.subscribe((cat) => {
    //   console.log(JSON.stringify(cat));
    // })
    this.irvineRef = this.db.collection<any>(`/events/NextGen/days/1/attendees`, ref => {
      // return ref.where('membership', '==', 'associate')
      return ref
    })
    this.day1Ref = this.db.collection<any>(`/events/leadership-summit/days/1/attendees`, ref => {
      // return ref.where('membership', '==', 'associate')
      return ref
    })
    this.day2Ref = this.db.collection<any>(`/events/leadership-summit/days/2/attendees`, ref => {
      // return ref.where('membership', '==', 'associate')
      return ref
    })
    this.irvineRef.valueChanges().subscribe((peeps) => {
      if (peeps) {
        this.irvinea = peeps.filter(peep => {
          return peep.membership == 'associate';
        })
        this.irvineg = peeps.filter(peep => {
          return peep.membership != 'associate';
        })
        // this.exportPeeps();

      }
      // console.log(this.irvinea)
      // this.exportPeeps()
    })
    this.day1Ref.valueChanges().subscribe((peeps) => {
      this.day1a = peeps.filter(peep => {
        return peep.membership == 'associate';
      })
      this.day1g = peeps.filter(peep => {
        return peep.membership != 'associate';
      })
    })
    this.day2Ref.valueChanges().subscribe((peeps) => {
      this.day2a = peeps.filter(peep => {
        return peep.membership == 'associate';
      })
      this.day2g = peeps.filter(peep => {
        return peep.membership != 'associate';
      })
    })

  }

  ngOnInit() {
  }

  exportPeeps(peeps: any[], name: string, type: string) {
    const filename = name+'.csv';
    if (type == 'a') {
      const blob = new Blob(this.peepsToBlob(peeps), {type: 'text/csv'});
      saveAs(blob, filename);
    } else {
      const blob = new Blob(this.guestsToBlob(peeps), {type: 'text/csv'});
      saveAs(blob, filename)
    }
  }

  peepsToBlob(peeps: any[]) {
    console.log(peeps);
    var blobText='id,email,membership,name,phone,time\n';
    var blobArray: string[] = [blobText]
    for (let peep of peeps) {
      let line: string = peep.id +','+peep.email+','+peep.membership+','+peep.name+','+peep.phone+','+(new Date(peep.time).toLocaleDateString())+'\n';
      blobArray.push(line);
    }
    return blobArray;
  }

  guestsToBlob(peeps: any[]) {
    var blobText='email,name,sponsor,membership,phone,address,city,state,zip,time\n';
    var blobArray: string[] = [blobText]
    for (let peep of peeps) {
      let line: string = peep.email +','+peep.name+','+peep.sponsor+','+peep.membership+','+peep.phone+','+peep.address+','+peep.city+','+peep.state+','+peep.zip+','+(new Date(peep.time).toLocaleDateString())+'\n';
      blobArray.push(line);
    }
    return blobArray;
  }
}
