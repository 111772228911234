import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-dallas',
  templateUrl: './dallas.component.html',
  styleUrls: ['./dallas.component.css']
})
export class DallasComponent implements OnInit {
  eventID: string;
  eventName: string;
  eventDay: string;
  checkinForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private db: AngularFirestore,
    private router: Router,
  ) {
    // this.route.params.subscribe(routeParams => {
    //   this.eventID = routeParams['event'];
    //   this.eventDay = routeParams['day'];
    //   if (this.eventID == 'NextGen') {
    //     this.eventName = "Next Generation - Irvine";
    //   }
    //   if (this.eventID == 'leadership-summit') {
    //     this.eventName = 'Leadership Summit Training Conference';
    //   }
    // });

  }

  ngOnInit() {
    this.checkinForm = this.fb.group({
      first:  [,Validators.required],
      last:  [,Validators.required],
      email: [,[Validators.email,Validators.required]],
      phone:  [,Validators.required],
      day: '',
      timeOfDay:'',
      interest: ''
    })
  }

  submitHandler() {
    let data: any = {
      first:  this.checkinForm.get('first').value,
      last:  this.checkinForm.get('last').value,
      email: this.checkinForm.get('email').value,
      phone:  this.checkinForm.get('phone').value,
      day: this.checkinForm.get('day').value,
      timeOfDay: this.checkinForm.get('timeOfDay').value,
      interest: this.checkinForm.get('interest').value,
      // id: null,
      // sponsor: null,
      // address: null,
      // city: null,
      // state: null,
      // zip: null,
      time: Date.now()
    }
    try {
      this.db.collection('dallasGuests').add(data)
        .then(() => {
          this.router.navigateByUrl(`dallas-in`);
        });

    } catch(err) {
      console.error(err)
    }
  }
}
